import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import interactionPlugin from '@fullcalendar/interaction';
import './playerCalendar.css'
import { useEffect, useState } from "react";

const renderEventContent = (eventInfo) => {
  return (
    <div style={{
      display: 'flex',
      height: '100%',

    }}>
      <img src={'images/スマッシュボール.png'} alt="" style={{ width: '30px', height: '30px', display: 'block', margin: 'auto' }} />
    </div>
  );
};

export const PlayerCalendar = ({ player }) => {
  const [events, setEvents] = useState([])

  const HOURS = 24;

  const bitToEventsConverter = (bitCalendar) => {
    const events = new Array();
    if (bitCalendar === undefined) {
      return events
    }
    bitCalendar.split('').filter((bit) => !isNaN(bit)).forEach((bit, index) => {
      if (bit !== '1') { return }
      const dayOfWeek = Math.floor(index / HOURS);
      const startHour = index % HOURS;
      const startTime = startHour + ":00";
      const id = "d" + dayOfWeek + "h" + startHour

      events.push({
        id: id,
        daysOfWeek: [dayOfWeek],
        startTime: startTime
      })
    })
    return events
  }

  useEffect(() => {
    setEvents(bitToEventsConverter(player?.bitCalendar))
  }, [player])
  return (
    <>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>１週間のスケジュール</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "5px" }}>
        <img src={'images/スマッシュボール.png'} alt="" style={{ width: '20px', height: '20px' }} />
        <p style={{ marginLeft: "5px" }}>空いている日時</p>
      </div>

      <div style={{ background: "white", maxWidth: "800px", margin: "10px auto", borderRadius: "10px" }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
          initialView="timeGridWeek"
          locales={[jaLocale]}
          locale='ja'
          allDaySlot={false}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          dayHeaderFormat={{ weekday: 'short', omitCommas: true }}

          slotDuration="01:00:00"
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false
          }}
          events={events}
          aspectRatio={1.6}
          eventContent={renderEventContent}
          contentHeight='auto'

        />
      </div>
    </>
  )
}