import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, signOut, TwitterAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID
}

const app = initializeApp(firebaseConfig);

const auth = getAuth();

const provider = new TwitterAuthProvider();

export const loginWithTwitter = async () => {
  try {
    const result = await signInWithPopup(auth, provider)
  } catch (error) {
    throw error;
  }
}

export const logout = () => {
  signOut(auth)
}
