import { CharacterImg } from "./CharacterImg"

import "../../styles/blackButton.css"
import { NoConditionButton } from "./NoConditionButton"

export const SelectCharacterButton = ({ characterName, onClick }) => {
  return (
    <>
      {characterName !== null ?
        <div className="blackButton" onClick={onClick} style={{ cursor: "pointer" }}>
          <CharacterImg characterName={characterName}></CharacterImg>
        </div>
        :
        <div className="blackButton" onClick={onClick} style={{ cursor: "pointer" }}>
          <NoConditionButton />
        </div>
      }
    </>
  )
}