import { Rank } from "./modal/Rank"
import { NoConditionButton } from "./NoConditionButton"

export const SelectRankButton = ({ id, onClick }) => {
  return (
    <>
      {id !== -1 ?
        <div className="blackButton" onClick={onClick} style={{ cursor: "pointer", WebkitTouchCallout: 'none' }}>
          <Rank id={id} />
        </div>
        :
        <div className="blackButton" onClick={onClick} style={{ cursor: "pointer" }}>
          <NoConditionButton />
        </div>
      }
    </>
  )
}