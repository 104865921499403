import '../../styles/spinner.css'

export const LoadingScreen = () => {
  return (
    <div className="loading-overlay" style={{ zIndex: "2", position: "fixed", top: "0px", left: "0px", width: "100%", height: "120%", background: "white", overflowY: "auto" }}>
      <div className="spinner">
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", maxWidth: "190px", height: "80px" }}>
          <img
            src="images/ロゴ.png"
            alt="ロゴ"
            style={{ width: "100%", objectFit: "contain", margin: "auto 10px" }}
          />
          <p style={{ fontSize: "20px", marginTop: "10px", fontWeight: "bold" }}>Loading...</p>
        </div>
      </div>
    </div>
  );
};