import './style.css'
import { CharacterImg } from "../CharacterImg"
import { charactersData } from "../../data/CharactersData"
import { NoConditionButton } from '../NoConditionButton'
export const CharacterSelectModal = ({ onClose, setSelectedCharacter, setCurrentPage }) => {
  const onClick = (character) => {
    setCurrentPage(0)
    setSelectedCharacter(character)
    onClose();
  }

  return (
    <div className="modal-overlay" style={{ zIndex: "2", position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div style={{ width: "85%", maxWidth: "510px", background: "white", borderRadius: "10px", margin: "140px auto", padding: "20px 20px", position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px", marginRight: "10px" }}>
          <p style={{ fontSize: "20px", fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden" }}>希望ファイター</p>
          <button
            className="blackButton"
            style={{
              display: "flex", justifyContent: "center", alignItems: "center", border: "none", background: "transparent", cursor: "pointer", color: "black"
            }}
            onClick={onClose}
          >
            <img src="images/バツ.png" alt="バツ" style={{ height: "30px", width: "30px", objectFit: "contain", WebkitTouchCallout: 'none' }} />
          </button>
        </div>

        <div className="scrollable" style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
          gridGap: "1px",
          maxHeight: "400px",
          margin: "10px 10px",
          padding: "10px 10px",
          overscrollBehavior: "none",
          overflowY: "scroll",
        }}>
          <div className="blackButton"
            style={{
              cursor: "pointer"
            }} onClick={() => onClick({ id: -1, characterName: null })}>
            <NoConditionButton />
          </div>
          {charactersData.map((character) => (
            <div className="blackButton"
              style={{
                cursor: "pointer"
              }} onClick={() => onClick(character)}>
              <CharacterImg characterName={character.characterName} ></CharacterImg>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}