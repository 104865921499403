import { DndContext, DragOverlay, PointerSensor, pointerWithin, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { useState } from "react"
import { charactersData } from "../data/CharactersData"
import { arrayMove } from "@dnd-kit/sortable"
import { RequestTierList } from "./RequestTierList"
import { CharacterPool } from "./CharacterPool"
import { Character } from "./Character"

export const MyRequestTierTable = ({ requestTierLists, setRequestTierLists }) => {

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor)
  )

  const [activeCharacter, setActiveCharacter] = useState()

  const findRequestTierList = (unique) => {
    if (unique === null) return null


    if (requestTierLists.some((requestTierList) => requestTierList.id === unique)) {
      return requestTierLists.find((requestTierLists) => requestTierLists.id === unique) ?? null

    }

    const id = unique
    const itemWithRequestTierListId = requestTierLists.flatMap((requestTierLists => {
      const requestTierListId = requestTierLists.id
      return requestTierLists.characters.map((character) => ({ characterId: character.id, requestTierListId: requestTierListId }))
    }))

    const requestTierListId = itemWithRequestTierListId.find((character) => character.characterId === id)?.requestTierListId
    return requestTierLists.find((requestTierList) => requestTierList.id === requestTierListId) ?? null
  }

  const handleDragStart = (event) => {
    const { active } = event
    setActiveCharacter(charactersData.find((character) => character.id === active.id))
  }

  const handleDragCancel = () => {
    setActiveCharacter(null)
  }

  const handleDragOver = (event) => {
    // activeは今触っているオブジェクト、overは重なっているオブジェクト、deltaは移動情報？
    const { active, over, delta } = event
    // 動かしているファイターのid
    const activeId = active.id
    // 重なっているファイターのid
    const overId = over ? over.id : null

    // 動かしたファイターの元々のtier
    const activeRequestTierList = findRequestTierList(activeId)
    // 動かしたファイターが今重なっているtier
    const overRequestTierList = findRequestTierList(overId)

    if (!activeRequestTierList || !overRequestTierList || activeRequestTierList === overRequestTierList) {
      return null
    }
    setRequestTierLists((prevState) => {
      const activeCharacters = activeRequestTierList.characters
      const overCharacters = overRequestTierList.characters
      // handleDragEndと違う場所に書かれているのが気になる
      const activeIndex = activeCharacters.findIndex((character) => character.id === activeId)
      const overIndex = overCharacters.findIndex((character) => character.id === overId)
      // 今の場所で挿入するとした場合のoverTierListでのindexを求める（intを返す）
      const newIndex = () => {
        // && delta.y > 0　いる？
        const putOnRightCharacter = overIndex === overCharacters.length - 1 && delta.y > 0
        const modifier = putOnRightCharacter ? 1 : 0
        // overIndexって0以上かnull?
        return overIndex >= 0 ? overIndex + modifier : overCharacters.length + 1
      }
      return prevState.map((requestTierList) => {
        // 動かしたファイターが含まれるTierの更新
        if (requestTierList.id === activeRequestTierList.id) {
          requestTierList.characters = activeCharacters.filter((character) => character.id !== activeId)
          return requestTierList
        } else if (requestTierList.id === overRequestTierList.id) {
          requestTierList.characters = [
            ...overCharacters.slice(0, newIndex()),
            activeCharacters[activeIndex],
            ...overCharacters.slice(newIndex(), overCharacters.length)
          ].filter((character) => character !== undefined) //この記述を入れないとnullになる
          return requestTierList
        } else {
          // 関係ないTier
          return requestTierList
        }
      })
    })
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // 動かしているファイターのid
    const activeId = active.id
    // 重なっているファイターのid
    const overId = over ? over.id : null
    // 動かしたファイターの元々のtier
    const activeRequestTierList = findRequestTierList(activeId)
    // 動かしたファイターが今重なっているtier
    const overRequestTierList = findRequestTierList(overId)

    if (!activeRequestTierList || !overRequestTierList || activeRequestTierList !== overRequestTierList) {
      return null
    }
    const activeIndex = activeRequestTierList.characters.findIndex((character) => character.id === activeId)
    const overIndex = overRequestTierList.characters.findIndex((character) => character.id === overId)
    // よくわからん
    if (activeIndex !== overIndex) {
      setRequestTierLists((prevState) => {
        return prevState.map((requestTierList) => {
          if (requestTierList.id === activeRequestTierList.id) {
            requestTierList.characters = arrayMove(overRequestTierList.characters, activeIndex, overIndex)
            return requestTierList
          } else {
            return requestTierList
          }
        })
      })
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>対戦したいファイター</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragCancel={handleDragCancel}>
        <div>
          {requestTierLists.map((requestTierList) => {
            if (requestTierList.id === 0) {
              return null
            }
            return <RequestTierList key={requestTierList.id} id={requestTierList.id} characters={requestTierList.characters} requestTierList={requestTierList}></RequestTierList>
          })}
          {requestTierLists.map((requestTierList) => {
            if (requestTierList.id === 0) {
              return <CharacterPool key={requestTierList.id} id={requestTierList.id} characters={requestTierList.characters} />
            }
            return null
          })}
        </div>

        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {activeCharacter ? <Character id={activeCharacter.id} characterName={activeCharacter.characterName} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  )
}