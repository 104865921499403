export const playerTierListData = [
  {
    id: 2000,
    rank: 'スマメイト\n2000〜',
    characters: [],
  },
  {
    id: 1900,
    rank: 'スマメイト\n1900〜',
    characters: [],
  },
  {
    id: 1800,
    rank: 'スマメイト\n1800〜',
    characters: [],
  },
  {
    id: 1700,
    rank: 'スマメイト\n1700〜',
    characters: [],
  },
  {
    id: 1600,
    rank: 'スマメイト\n1600〜',
    characters: [],
  },
  {
    id: 1500,
    rank: 'スマメイト\n1500〜',
    characters: [],
  },
  {
    id: 1400,
    rank: 'スマメイト\n1400〜',
    characters: [],
  },
  {
    id: 1300,
    rank: 'スマメイト\n1300〜',
    characters: [],
  },
  {
    id: 1200,
    rank: 'VIP以上',
    characters: [],
  },
  {
    id: 1100,
    rank: '未VIP\n修行ゾーン',
    characters: [],
  },
  {
    id: 1000,
    rank: '未VIP\nカオス',
    characters: [],
  },
];