import { useEffect, useState } from "react";

export const OfferBattleModal = ({ onClose, player, user }) => {

  const hostname = window.location.hostname;

  const protocol = window.location.protocol;

  const text = encodeURIComponent(`【SMASH CONNECT対戦申請】\n・私の使用ファイター：\n・対戦希望ファイター：\n・希望日時：\n・私のマイページリンク：\n${protocol}//${hostname}/playerpage?userid=${user?.id}\n対戦よろしくお願いします\n＃smash_connect`)

  const [replyUrl, setReplyUrl] = useState()

  const extractTweetId = (url) => {
    const match = url.match(/status\/(\d+)/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    if (player?.postLink) {
      const id = extractTweetId(player.postLink)
      if (id) {
        setReplyUrl(`https://twitter.com/intent/tweet?in_reply_to=${id}&text=${text}`)
      }
    }
  }, [player])

  return (
    <div className="modal-overlay" style={{ zIndex: "2", position: "fixed", top: "0px", left: "0px", width: "100%", height: "120%", backgroundColor: "rgba(0, 0, 0, 0.5)", overflowY: "auto" }}>
      <div style={{ width: "85%", maxWidth: "510px", background: "white", borderRadius: "10px", margin: "80px auto", padding: "20px 20px", position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px", marginRight: "10px" }}>
          <p style={{ fontSize: "26px", fontWeight: "bold", margin: "5px" }}>対戦を申し込む</p>
          <button
            className="blackButton"
            style={{
              display: "flex", justifyContent: "center", alignItems: "center", border: "none", background: "transparent", cursor: "pointer", color: "black"
            }}
            onClick={onClose}
          >
            <img src="images/バツ.png" alt="バツ" style={{ height: "30px", width: "30px", objectFit: "contain", WebkitTouchCallout: 'none' }} />
          </button>
        </div>
        <div className="scrollable" style={{
          height: "350px",
          overscrollBehavior: "none",
          overflowY: "scroll",
        }}>

          <p style={{ fontSize: "12px", margin: "10px" }}>他プレイヤーとのやりとりはポストのリプライ欄を用いて行います。以下の手順に従い、対戦を申し込んで下さい。
          </p>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>1. 相手の情報を確認する</p>
          <p style={{ fontSize: "12px", margin: "10px", marginTop: "0px" }}>相手に配慮して、必ず相手のファイターランクとスケジュールを確認してから対戦申請をするようにして下さい。</p>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>2. リプライ欄から対戦を申し込む</p>
          <p style={{ fontSize: "12px", margin: "10px", marginTop: "0px" }}>あなたの使用ファイター、対戦する希望するファイター、日時を埋めてリプライして下さい。</p>
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center"
          }}>
            {!replyUrl && <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", margin: "5px" }}>
              ポストが登録されていません。
            </p>}
            <a href={replyUrl}
              className="blackButton"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px auto",
                width: "150px",
                height: "35px",
                color: "white",
                background: "black",
                fontSize: "12px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "10px",
                textDecoration: "none",
                padding: "10px",
                cursor: "pointer"
              }}
            ><img src="images/X.jpg" alt="X" style={{ width: "20px", objectFit: "cover", marginRight: "5px" }} />リプライへ進む
            </a>
          </div>

          <p style={{ fontSize: "20px", fontWeight: "bold", marginTop: "10px" }}>4. やりとりを続け対戦する</p>
          <p style={{ fontSize: "12px", margin: "10px", marginTop: "0px" }}>そのままリプライ欄でやりとりを続けて、専用部屋で対戦して下さい。</p>
        </div>
      </div>
    </div>
  )
}