import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { axiosInstance } from "../configs/axios";

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  const [beingLoggedIn, setBeingLoggedIn] = useState()

  const [screenName, setScreenName] = useState()

  const value = {
    user,
    beingLoggedIn,
    setBeingLoggedIn,
    screenName
  }

  useEffect(() => {
    setBeingLoggedIn(true)
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, loginUser => {
      if (loginUser) {
        axiosInstance.post("/login", { xid: loginUser.uid, name: loginUser.displayName, screenName: loginUser?.reloadUserInfo?.screenName })
          .then(() => axiosInstance.post('/myuser', loginUser.uid))
          .then(response => {
            setUser(response.data);
            setScreenName(loginUser?.reloadUserInfo?.screenName);
            setBeingLoggedIn(false)
          })
      } else {
        setUser(null)
        setBeingLoggedIn(false)
      }
    })
    return unsubscribe
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}