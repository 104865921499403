import html2canvas from "html2canvas";

export const ScreenshotButton = ({type}) => {

  // html2canvas で得られる URI を用いてダウンロードさせる関数
  // Ref: https://stackoverflow.com/questions/31656689/how-to-save-img-to-users-local-computer-using-html2canvas
  const saveAsImage = uri => {
    const downloadLink = document.createElement("a");
    if (typeof downloadLink.download === "string") {
      downloadLink.href = uri;
      // ファイル名
      downloadLink.download = type === "tier-table" ? "ファイターランク" : "対戦したいファイター";
      // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
      document.body.appendChild(downloadLink);
      // ダウンロードリンクが設定された a タグをクリック
      downloadLink.click();
      // Firefox 対策で追加したリンクを削除しておく
      document.body.removeChild(downloadLink);
    } else {
      window.open(uri);
    }
  }

  const onClickExport = () => {
    const target = document.getElementById(type);
    const clone = target.cloneNode(true);

    const parent = document.createElement('div');

    const img = document.createElement('img');
    img.src = "images/ロゴ.png";
    img.alt = "ロゴ";
    img.style.width = "200px";
    img.style.objectFit = "contain";
    img.style.margin = "auto 10px";

    parent.appendChild(img)

    parent.appendChild(clone)
    parent.style.padding = '20px'
    parent.style.width = `600px`;
    parent.style.position = 'absolute';
    parent.style.top = '-9999px'; // 画面外に配置

    document.body.appendChild(parent);

    html2canvas(parent, {
      useCORS: true, // 外部リソースのクロスオリジン問題を回避するため
    }).then(canvas => {
      // クローン要素を削除
      document.body.removeChild(parent);
      const targetImgUri = canvas.toDataURL("image/png");
      saveAsImage(targetImgUri);
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", fontWeight: "bold", fontSize: "12px" }}>
      <p>
        ダウンロード
      </p>
      <button className="blackButton" onClick={onClickExport} style={{ background: "white", border: "none", margin: "5px", cursor: "pointer", WebkitTouchCallout: 'none' }}>
        <img src="images/ダウンロード.png" alt="ダウンロード" style={{ width: "18px", objectFit: "cover" }} />
      </button>
    </div>

  )
}


