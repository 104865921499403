import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useCallback, useEffect, useState } from "react"

export const useModal = () => {
  const [openModalId, setOpenModalId] = useState(null);

  const options = {
    reserveScrollBarGap: true,
  };

  useEffect(() => {
    if (openModalId) {
      const target = document.getElementsByClassName('scrollable')[0]
      disableBodyScroll(target, options);
    }
  }, [openModalId])

  const onOpen = useCallback((id) => {
    changeThemeColor('rgba(0, 0, 0, 0.5)');
    setOpenModalId(id);
  }, []);

  const onClose = useCallback(() => {
    changeThemeColor('#ffffff');
    clearAllBodyScrollLocks();
    setOpenModalId(null);
  }, []);

  // 特定のモーダルが開いているかどうかを確認するヘルパー関数
  const isOpen = useCallback((id) => {
    return openModalId === id;
  }, [openModalId]);

  return { isOpen, onOpen, onClose };
};

function changeThemeColor(color) {
  const metaThemeColor = document.querySelector('meta[name=theme-color]');
  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', color);
  }
}