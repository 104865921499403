import { useEffect, useState } from "react";
import { PlayerPlate } from "./PlayerPlate"
import ReactPaginate from "react-paginate";
import './Pagination.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useModal } from "../../hooks/useModal";
import { CharacterSelectModal } from "./modal/characterSelectModal";
import { SelectCharacterButton } from "./SelectCharacterButton";
import { charactersData } from "../data/CharactersData";
import { SelectRankButton } from "./SelectRankButton";
import { rankData } from "../data/rankData";
import { RankSelectModal } from "./modal/RankSelectModal";
import { axiosInstance } from "../../configs/axios";

import "../../styles/externalLink.css"

export const PlayerPlateList = ({ setIsLoading }) => {

  const setConditions = () => {
    const defaultPage = JSON.parse(sessionStorage.getItem('currentPage')) || 0;
    // id=-1は条件無しを意味する
    const defaultSelectedCharacter = JSON.parse(sessionStorage.getItem('selectedCharacter')) || { id: -1, characterName: null };
    const defaultSelectedRank = JSON.parse(sessionStorage.getItem('selectedRank')) || {id: -1, rank: null, rate: null};

    return { defaultPage, defaultSelectedCharacter, defaultSelectedRank };
  }

  const { defaultPage, defaultSelectedCharacter, defaultSelectedRank } = setConditions();

  const [selectedCharacter, setSelectedCharacter] = useState(defaultSelectedCharacter)

  const [selectedRank, setSelectedRank] = useState(defaultSelectedRank)

  const { isOpen, onClose, onOpen } = useModal();

  const [players, setPlayers] = useState([])

  const [currentPage, setCurrentPage] = useState(defaultPage);

  const [totalPages, setTotalPages] = useState(0);

  const [result, setResult] = useState(0);

  const fetchPlayers = () => {
    setIsLoading(true)
    axiosInstance.get(`/playerplateinfos?character=${selectedCharacter.id}&rank=${selectedRank.id}&page=${currentPage}`)
      .then(response => {
        setPlayers(response.data.content);
        setTotalPages(response.data.totalPages);
        setResult(response.data.totalElements)
        setIsLoading(false)
      })
  };

  const saveConditions = () => {
    sessionStorage.setItem('currentPage', JSON.stringify(currentPage));
    sessionStorage.setItem('selectedCharacter', JSON.stringify(selectedCharacter));
    sessionStorage.setItem('selectedRank', JSON.stringify(selectedRank));
  }

  useEffect(() => {
    saveConditions();
    fetchPlayers();
  }, [selectedCharacter, selectedRank, currentPage]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const arrowIcon = (iconName) => {
    return (
      <i className={`fas fa-chevron-${iconName}`}></i>
    );
  };

  const style = {
    margin: "10px auto",
  }

  return (
    <div>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>プレイヤー検索</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        対戦希望するファイターとランクを設定し、プレイヤーを検索しましょう！<br />
        ランクの赤色と青色のパネルの数値は、それぞれ以下のサイトの指標を表しています。それぞれの指標については、リンク先よりご確認ください。
        <div style={{ margin: "5px", marginLeft: "30px" }}>
          <div style={{ display: "flex" }}>
            <p style={{ color: "blue", fontSize: "12px", fontWeight: "bold" }}>
              青色
            </p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>
              ：　<a className="externalLink" href="https://smashmate.net/ranking/" target="_blank" rel="noopener noreferrer">スマメイト様のレート</a><br />
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ color: "red", fontSize: "12px", fontWeight: "bold" }}>
              赤色
            </p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>
              ：　<a className="externalLink" href="https://kumamate.net/vip/" target="_blank" rel="noopener noreferrer">クマメイト様の世界戦闘力の段位</a><br />
            </p>
          </div>
        </div>
        <p style={{ fontSize: "10px", marginLeft: "30px" }}>
          例）赤色1600・青色21のパネル：<br />・「スマメイト様のレートが1600以上」<br />・「クマメイト様の世界戦闘力の段位が21（魔境卒業）」<br />のいずれかを満たしている。<br />
        </p>
        検索条件に設定したランク以上のプレイヤーが結果に出力されます。
      </p>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>検索条件</p>
      <div style={{ display: "flex", justifyContent: "space-around", padding: "20px", paddingTop: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: "flex" }}>
            <img src={"images/検索.png"} alt="検索" style={{ width: "20px", objectFit: "cover", WebkitTouchCallout: 'none' }} />
            <p style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "3px", marginRight: "15px" }}>ファイター</p>
          </div>
          <SelectCharacterButton characterName={selectedCharacter.characterName} onClick={() => onOpen('char')} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: "flex" }}>
            <img src={"images/検索.png"} alt="検索" style={{ width: "20px", objectFit: "cover", WebkitTouchCallout: 'none' }} />
            <p style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "3px", marginRight: "20px" }}>ランク</p>
          </div>
          <SelectRankButton id={selectedRank.id} onClick={() => onOpen('rank')}></SelectRankButton>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <p style={{ fontSize: "14px", fontWeight: "bold", }}>検索結果：{result}件</p>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <img src={'images/登録済み.png'} alt="登録済み" style={{ objectFit: "cover", width: '15px', height: '15px' }} />
          <p style={{ marginLeft: "5px", fontSize: "12px" }}>ポスト登録済みプレイヤー</p>
        </div>
      </div>
      {isOpen('char') && <CharacterSelectModal onClose={onClose} setSelectedCharacter={setSelectedCharacter} setCurrentPage={setCurrentPage} />}
      {isOpen('rank') && <RankSelectModal onClose={onClose} setSelectedRank={setSelectedRank} setCurrentPage={setCurrentPage} />}

      {players.map((player) => (
        <PlayerPlate key={player.userId} player={player} />
      ))}

      <ReactPaginate
        breakLabel={'...'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeLinkClassName="active"
        previousLinkClassName="previous-link"
        nextLinkClassName="next-link"
        previousLabel={arrowIcon('left')}
        nextLabel={arrowIcon('right')}
        disabledClassName="disabled-button"
        forcePage={currentPage}
        style={style} />

    </div>

  )
}