import { Link } from "react-router-dom"
import { Header } from "../header/Header"
import { Footer } from "../footer/Footer"

export const QAPage = () => {
  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      <Header />
      <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>トップページ</Link>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>Q&A</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q1. Xのログインは必要？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        ご自身のファイターランク情報を本サイトに登録するためには、Xでのログインが必要です。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q2. ポストの登録は必要？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        他ユーザーがあなたに対戦を申し込む際に、あなたが登録したポストのリプライ欄を通してやり取りするため、登録が必要です。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q3. 鍵垢アカウントでやり取りはできる？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        ポストのリプライ欄を用いてやり取りを行う性質上、鍵のかかったXアカウントでやり取りすることはできません。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q4. サイト内でやり取りはできないの？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        サイト内でやり取りはできません。現状他ユーザーとのやり取りは、ポストのリプライ欄を用いてください。別手段でのやり取りは現在検討中です。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q5. スマメイトとクマメイトの強さの基準が合っていない？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        製作者の独断と偏見により2つの指標を紐付けたため、基準のずれについてはご了承ください。初心者層から上級者層まで幅広く本サイトをご利用いただけるよう、2つの指標を組み合わせる形に至りました。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q6. スマメイトとクマメイトの基準どちらかしか満たしていないけど、どう登録したら良い？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        ファイターがランクのいずれか一方の基準を満たしていれば、ご登録下さい。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q7. 虚偽のランクを登録しているユーザーがいた。どうしたら良い？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        ユーザーが虚偽の登録をしていることを報告する機能は設けておりません。いいね（ハート）の数やユーザーのXを見ることで各自判断して下さい。
      </p>
      <p style={{ display: "block", fontWeight: "bold", fontSize: "14px", margin: "12px" }}>
        Q8. バグを見つけた。どうしたら良い？
      </p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginTop: "10px", marginBottom: "20px" }}>
        <a className="externalLink" target="_blank" rel="noopener noreferrer" href="https://forms.gle/VfwvuPxLnFVaWMhC9">報告フォーム</a>を設けていますので、こちらからご報告ください。
      </p>
      <Footer />
    </div>
  )
}