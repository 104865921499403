import { TierList } from "./TierList"

export const PlayerTierTable = ({ tierLists }) => {
  return (
    <>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>ファイターランク</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <div id='tier-table'>
        {tierLists.map((tierList) => {
          return <TierList key={tierList.id} id={tierList.id} rank={tierList.rank} characters={tierList.characters} tierList={tierList}></TierList>
        })}
      </div>
    </>
  )
}