import './style.css'
import { rankData } from "../../data/rankData"
import { Rank } from "./Rank"
import { NoConditionButton } from '../NoConditionButton'

export const RankSelectModal = ({ onClose, setSelectedRank, setCurrentPage }) => {
  const onClick = (rank) => {
    setCurrentPage(0)
    setSelectedRank(rank)
    onClose();
  }
  return (
    <div className="modal-overlay" style={{ zIndex: "2", position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div style={{ width: "85%", maxWidth: "510px", background: "white", borderRadius: "10px", margin: "140px auto", padding: "20px 20px", position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px", marginRight: "10px" }}>
          <p style={{ fontSize: "20px", fontWeight: "bold", margin: "10px" }}>希望ランク</p>
          <button
            className="blackButton"
            style={{
              display: "flex", justifyContent: "center", alignItems: "center", border: "none", background: "transparent", cursor: "pointer", color: "black"
            }}
            onClick={onClose}
          >
            <img src="images/バツ.png" alt="バツ" style={{ height: "30px", width: "30px", objectFit: "contain", WebkitTouchCallout: 'none' }} />
          </button>
        </div>
        <div style={{ margin: "5px", marginLeft: "30px" }}>
        <div style={{ display: "flex" }}>
          <p style={{ color: "blue", fontSize: "12px", fontWeight: "bold" }}>
            青色
          </p>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            ：　<a className="externalLink" href="https://smashmate.net/ranking/" target="_blank" rel="noopener noreferrer">スマメイト様のレート</a><br />
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ color: "red", fontSize: "12px", fontWeight: "bold" }}>
            赤色
          </p>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            ：　<a className="externalLink" href="https://kumamate.net/vip/" target="_blank" rel="noopener noreferrer">クマメイト様の世界戦闘力の段位</a><br />
          </p>
        </div>
      </div>
        <div className="scrollable" style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
          gridGap: 1,
          maxHeight: "400px",
          margin: "10px 10px",
          padding: "10px 10px",
          overscrollBehavior: "none",
          overflowY: "scroll",
        }}>
          <div className="blackButton"
            style={{
              cursor: "pointer"
            }} onClick={() => onClick({id: -1, rank: null, rate: null})}>
            <NoConditionButton />
          </div>
          {rankData.slice(1).map((rank) => (
            <div className="blackButton"
              style={{
                cursor: "pointer",
                WebkitTouchCallout: 'none'
              }} onClick={() => onClick(rank)}>
              <Rank id={rank.id}></Rank>
            </div>

          ))}
        </div>
      </div>
    </div>
  )
}