export const NoConditionButton = () => {
  return (
    <div style={style}>
      <img
        src={"images/条件無し.png"}
        alt={`条件無し`}

        style={{
          borderRadius: "8px",
          width: "50px",
          objectFit: "cover",
          WebkitTouchCallout: 'none'
        }}
      />
    </div>
  )
}

const style = {
  background: "rgba(245,247,249,1.00)",
  width: "50px",
  height: "50px",
  boxShadow: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
  borderRadius: "8px",
};