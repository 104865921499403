import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";


export const Character = ({ id, characterName, img }) => {
  const { attribute, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: id
  })

  const style = {
    opacity: isDragging ? "0.4" : "1",
    cursor: isDragging ? "grabbing" : "grab",
    background: "rgba(245,247,249,1.00)",
    width: "50px",
    height: "50px",
    boxShadow: isDragging
      ? "none"
      : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
    borderRadius: "8px",
    transform: CSS.Transform.toString(transform),
    transition: transition,
    // 設定しないとスクロールが上手く動作しない
    touchAction: "none"
  };

  return (
    <div ref={setNodeRef} {...attribute} {...listeners} style={style} id={id}>
      <img
        src={"images/characters/" + characterName + ".png"}
        alt={`${characterName}`}

        style={{
          borderRadius: "8px",
          width: "50px",
          objectFit: "cover",
          WebkitTouchCallout: 'none'
        }}
      />
    </div>
  )
}