export const BlankImg = () => {
  return (
    <div style={{
      borderRadius: "8px",
      width: "50px",
      height: "50px",
      background: "black"
    }}>
    </div>
  )
}