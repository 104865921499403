export const rankData = [
  {id:0, rank:"", rate: ""},
  {id: 2600, rate: '2600', rank: null},
  {id: 2500, rate: '2500', rank: null},
  {id: 2400, rate: '2400', rank: null},
  {id: 2300, rate: '2300', rank: null},
  {id: 2200, rate: '2200', rank: null},
  {id: 2100, rate: '2100', rank: null},
  {id: 2000, rate: '2000', rank: '25'},
  {id: 1900, rate: '1900', rank: '24'},
  {id: 1800, rate: '1800', rank: '23'},
  {id: 1700, rate: '1700', rank: '22'},
  {id: 1600, rate: '1600', rank: '21'},
  {id: 1500, rate: '1500', rank: '20'},
  {id: 1400, rate: '1400', rank: '18'},
  {id: 1300, rate: '1300', rank: '16'},
  {id: 1200, rate: '1200', rank: '13'},
  {id: 1100, rate: '1100', rank: '10'},
  {id: 1000, rate: null, rank: '8'},
  {id: 900, rate: null, rank: '4'},
  {id: 800, rate: null, rank: '0'}
]