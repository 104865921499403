import { RequestTierList } from "./RequestTierList"

export const PlayerRequestTierTable = ({ requestTierLists }) => {
  return (
    <>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>対戦したいファイター</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <div id='request-tier-table'>
        {requestTierLists.map((request) => {
          return <RequestTierList request={request}></RequestTierList>
        })}
      </div>
    </>
  )
}