import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import interactionPlugin from '@fullcalendar/interaction';
import './playerCalendar.css'
import { useEffect } from "react";

const renderEventContent = (eventInfo) => {

  return (
    <div style={{
      display: 'flex',
      height: '100%',

    }}>
      <img src={'images/スマッシュボール.png'} alt="" style={{ width: '30px', height: '30px', display: 'block', margin: 'auto' }} />
    </div>
  );
};

export const MyCalendar = ({ user, events, setEvents }) => {

  const HOURS = 24;

  const DAYS = 7;

  useEffect(() => {
    setEvents(bitToEventsConverter(user?.bitCalendar));
  }, [user])

  const handleDateClick = (info) => {
    const clickedDate = info.date
    const dayOfWeek = clickedDate.getDay()
    const startHour = clickedDate.getHours()
    const startTime = clickedDate.getHours() + ":" + String(clickedDate.getMinutes()).padStart(2, '0');
    const newId = "d" + dayOfWeek + "h" + startHour
    const removedEvents = events.filter(({ id }) => id !== newId)
    if (events.length !== removedEvents.length) {
      setEvents(removedEvents)
      return
    }

    const newEvent = {
      id: newId,
      daysOfWeek: [dayOfWeek],
      startTime: startTime
    }

    setTimeout(() => {
      setEvents([...events, newEvent])
    }, 50); // 50ミリ秒遅延
  }

  const handleEventClick = (info) => {
    const deleteId = info.event.id
    setEvents(events.filter(({ id }) => id !== deleteId))
  }

  const bitToEventsConverter = (bitCalendar) => {

    const events = new Array();
    if (bitCalendar === undefined) {
      return events
    }

    bitCalendar.split('').filter((bit) => !isNaN(bit)).forEach((bit, index) => {
      if (bit !== '1') {
        return;
      }

      const dayOfWeek = Math.floor(index / HOURS);
      const startHour = index % HOURS;
      const startTime = startHour + ":00";
      const id = "d" + dayOfWeek + "h" + startHour

      events.push({
        id: id,
        daysOfWeek: [dayOfWeek],
        startTime: startTime
      })
    })

    return events
  }




  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>１週間のスケジュール</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      {/* <button onClick={onClick}>カレンダー登録</button> */}
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "5px" }}>
        <img src={'images/スマッシュボール.png'} alt="" style={{ width: '20px', height: '20px' }} />
        <p style={{ marginLeft: "5px" }}>空いている日時</p>
      </div>
      <div style={{ background: "white", width: "100%", maxWidth: "800px" }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
          initialView="timeGridWeek"
          locales={[jaLocale]}
          locale='ja'
          allDaySlot={false}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          dayHeaderFormat={{ weekday: 'short', omitCommas: true }}

          slotDuration="01:00:00"
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false
          }}
          events={events}
          aspectRatio={1.6}
          // selectable={true} // セル選択を有効にする
          // select={handleClick} // セル選択イベントハンドラを追加
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          contentHeight='auto'
        // longPressDelay={0}
        // eventLongPressDelay={0}
        // selectLongPressDelay={0}

        />
      </div>
    </>
  )
}