export const Rank = ({ id }) => {
  return (
    <div style={{
      background: "rgba(245,247,249,1.00)",
      width: "50px",
      height: "50px",
      boxShadow: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      borderRadius: "8px",
    }}>
      <img src={`images/ranks/${id !== 0 ? id : "blank"}.png`} alt={`${id}`} style={{
        borderRadius: "8px",
        width: "50px",
        height: "50px",
        objectFit: "cover",
        boxShadow: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
        userSelect: 'none',
        WebkitUserSelect: 'none'
      }} />
    </div>
  )
}
