import { Link } from "react-router-dom"
import { Footer } from "../footer/Footer"
import { Header } from "../header/Header"

export const TermsAndServicePage = () => {
  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      <Header />
      <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>トップページ</Link>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>利用規約</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginBottom: "20px" }}>
        当サイトの利用規約が記載されています。内容を理解した上で当サイトをご利用ください。
        当サイトは、スマブラ（スーパースマッシュブラザーズ）ユーザーの情報を検索し、
        ユーザー同士のマッチングを促進することを目的としています。
        スマブラユーザー同士の交流を促進するためのプラットフォームとしてご活用いただけます。
        また当サイトの利用には、「X（旧Twitter）」との連携によるログインが必要です。
        ユーザーの一意の識別と名前の取得を目的としていますが、取得した情報は、
        上記の目的以外には使用されません。
      </p>

      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>プライバシーポリシー</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginBottom: "20px" }}>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しています。
        トラフィックデータは匿名で収集されており、個人を特定するものではありません。
        また当サイトでは、第三者配信の広告サービス（Googleアドセンス）を利用しており、
        ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しています。
        クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、
        お客様個人を特定できるものではありません。
        Cookieを無効にする方法やGoogleアドセンスに関する詳細は「広告 – ポリシーと規約 – Google」
        をご確認ください。
      </p>

      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>免責事項</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <p style={{ display: "block", fontSize: "12px", margin: "12px", marginBottom: "20px" }}>
        当サイトからのリンクやバナーなどで移動したサイトで提供される情報、
        サービス等について一切の責任を負いません。
        またユーザー同士のやり取りに利用していただく「X（旧Twitter）」上で生じたトラブルや損害に関して、
        当サイトは一切の責任を負いません。
        ユーザー間でのコミュニケーションは、各自の責任において行ってください。
        トラブルが発生した場合も、当事者間で解決を図るものとします。
      </p>


      <Footer />
    </div>
  )
}