import { useEffect, useState } from "react";
import { axiosInstance } from "../../configs/axios";

export const RegisterPostModal = ({ onClose, user, screenName }) => {

  const [url, setUrl] = useState()

  const [postValidator, setPostValidator] = useState(false)

  const regex = new RegExp(`^https://x.com/${screenName}/status/.+$`, 'i');

  const hostname = window.location.hostname;

  const protocol = window.location.protocol;

  const tweetText = encodeURIComponent(`【SMASH CONNECT登録】\n対戦やり取り用のポストを投稿しました。\n${protocol}//${hostname}/playerpage?userid=${user?.id}\n＃smash_connect`);

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setUrl(text);
    } catch {
    }
  }

  const postPostLink = () => {
    if (!regex.test(url)) {
      setPostValidator(true)
      return
    }
    setPostValidator(false)
    axiosInstance.post(`/postlink/register?userid=${user.id}`, url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    user.postLink = url
    onClose()
  }

  useEffect(() => {
    setUrl(user?.postLink)
  }, [])

  return (
    <div className="modal-overlay" style={{ zIndex: "2", position: "fixed", top: "0px", left: "0px", width: "100%", height: "120%", backgroundColor: "rgba(0, 0, 0, 0.5)", overflowY: "auto" }}>
      <div style={{ width: "85%", maxWidth: "510px", background: "white", borderRadius: "10px", margin: "60px auto", padding: "20px 20px", position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px", marginRight: "10px" }}>
          <p style={{ fontSize: "26px", fontWeight: "bold", margin: "5px" }}>ポストの登録</p>
          <button
            className="blackButton"
            style={{
              display: "flex", justifyContent: "center", alignItems: "center", border: "none", background: "transparent", cursor: "pointer", color: "black"
            }}
            onClick={onClose}
          >
            <img src="images/バツ.png" alt="バツ" style={{ height: "30px", width: "30px", objectFit: "contain", WebkitTouchCallout: 'none' }} />
          </button>
        </div>
        <div className="scrollable" style={{
          height: "400px",
          overscrollBehavior: "none",
          overflowY: "scroll",
        }}>
          <p style={{ fontSize: "12px" }}>他プレイヤーとのやりとりはポストのリプライ欄を用いて行います。
            そのためポストの登録を行わなければ、
            他ユーザーからの対戦依頼を受け取ることができません。
          </p>
          <a href={user?.postLink}
            className="blackButton"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              width: "150px",
              height: "35px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              padding: "10px"
            }}
          >ポストを確認する
          </a>
          <p style={{ fontSize: "12px", marginBottom: "10px" }}>以下の手順に従い、本サイトにポストのリンクを登録して下さい。
          </p>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>1. ポストする</p>
          <p style={{ fontSize: "12px" }}>マイページのリンクをポストして下さい。投稿内容は自動で生成されますので、そのままポストして下さい。</p>
          <a href={`https://twitter.com/intent/tweet?text=${tweetText}`}
            className="blackButton"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              width: "150px",
              height: "35px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              padding: "10px"
            }}
          ><img src="images/X.jpg" alt="X" style={{ width: "20px", objectFit: "cover", marginRight: "5px" }} />ポストする
          </a>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>2. ポストのリンクをペーストする</p>
          <p style={{ fontSize: "12px" }}>X上でポストの共有用リンクをコピーし、本サイトの以下フォームにペーストして下さい。</p>
          <div style={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
            <button className="blackButton" onClick={handlePaste} style={{ background: "white", border: "none", margin: "5px", cursor: "pointer", WebkitTouchCallout: 'none' }}>
              <img src="images/ペースト.png" alt="保存" style={{ width: "25px", objectFit: "cover" }} />
              <p style={{ fontSize: "10px", fontWeight: "bold", width: "40px", color: "black" }}>ペースト</p>
            </button>
            <p style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "black", borderRadius: "3px", padding: "5px", whiteSpace: "nowrap", overflowX: "auto", margin: "7px" }}>
              {url ? url : "ペーストしてください"}
            </p>
          </div>
          {postValidator && <div><p style={{ margin: "0px auto", height: "20px", color: "red", fontSize: "12px", fontWeight: "bold" }}>
            あなたのポストをペーストして下さい。
          </p>
            <p style={{ display: "block", fontSize: "12px", marginBottom: "10px" }}>
              登録に失敗する場合は<a className="externalLink" target="_blank" rel="noopener noreferrer" href="https://forms.gle/gR6SXpaZX24wa8DK8">ポスト登録用フォーム</a>より、ご報告ください。
            </p>
          </div>
          }

          <p style={{ fontSize: "20px", fontWeight: "bold" }}>3. ポストを登録する</p>
          <button
            className="blackButton"
            onClick={postPostLink}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
              width: "150px",
              height: "35px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              padding: "10px",
              cursor: "pointer"
            }}
          ><img src="images/X.jpg" alt="X" style={{ width: "20px", objectFit: "cover", marginRight: "5px" }} />ポストを登録する
          </button>
        </div>
      </div>
    </div>
  )
}