import { Link } from "react-router-dom";
import { loginWithTwitter, logout } from "../../configs/firebaseConfig";
export const TopPageHeader = ({ user, beingLoggedIn, setBeingLoggedIn }) => {
  const handleClick = async () => {
    setBeingLoggedIn(true);
    try {
      await loginWithTwitter();
    } catch (error) {
      setBeingLoggedIn(false);
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Link to={"/"} style={{ display: "flex", width: "50%", maxWidth: "190px", height: "80px", }}>
        <img
          src={"images/ロゴ.png"}
          alt="ロゴ"
          style={{
            width: "100%",
            objectFit: "contain",
            margin: "auto 10px",
          }} />
      </Link>

      {user ? (
        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
          <Link className="blackButton" to={`/playerpage?userid=${user?.id}`} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "120px",
            color: "white",
            background: "black",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "10px",
            textDecoration: "none",
            margin: "5px"
          }}>マイページ</Link>
          <button className="blackButton" onClick={logout} style={{
            height: "35px",
            width: "120px",
            color: "white",
            background: "black",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "10px",
            margin: "5px",
            cursor: "pointer"
          }}>ログアウト</button>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <button disabled={beingLoggedIn} className="blackButton" onClick={handleClick} style={!beingLoggedIn ? {
            height: "35px",
            width: "120px",
            color: "white",
            background: "black",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "10px",
            margin: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          } :
            {
              height: "35px",
              width: "120px",
              color: "white",
              background: "gray",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              margin: "5px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }

          }><img src="images/X.jpg" alt="X" style={{ width: "20px", objectFit: "cover", marginRight: "5px" }} />でログイン</button>
        </div>
      )}
    </div>


  )
}